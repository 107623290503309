import { useState, useEffect, useContext, Fragment } from 'react';
import { Accordion, AccordionItem } from 'carbon-components-react';
import { useStepController } from '../../hooks';
import { PersonalInfoContext } from 'contexts';
import api, { endpoints } from 'api';
import { history } from 'routes';
import PersonalInfo from './PersonalInfo';
import HouseHoldInfo from './HouseHoldInfo';
import BankAccounts from './BankAccounts';
import EmployedIndex from './EmploymentIncome/EmployedIndex';
import AssetInvestmentInfo from './AssetInvestment/AssetInvestmentInfo';
import Navigation from 'components/Navigation';
import {
    STATUS_MARRIED,
    STATUS_MARRIED_SEPARATELY,
    STATUS_WIDOW_WITH_DEPENDENT,
} from '../../const';
import { AuthContext } from '../../contexts';
import { toUsCurrency } from 'lib';

function IncomeSummary() {
    const auth = useContext(AuthContext);
    const { previousStep } = useStepController();
    const { authDispatch } = auth;
    const { filingStatus } = useContext(PersonalInfoContext);
    const [extraInfo, setExtraInfo] = useState({});
    const [personalInfo, setPersonalInfo] = useState({});
    const [additionalInfo, setAdditionalInfo] = useState({});
    const [spouseInfo, setSpouseInfo] = useState({});
    const [spouseDetail, setSpouseDetail] = useState(false);
    const [householdInfo, setHouseholdInfo] = useState([]);
    const [personalBankAccounts, setPersonalBankAccounts] = useState([]);
    const [spouseBankAccounts, setSpouseBankAccounts] = useState([]);
    const [personalEmploymentIncome, setPersonalEmploymentIncome] = useState(
        {}
    );
    const [spouseEmploymentIncome, setSpouseEmploymentIncome] = useState({});
    const [selfEmployedPersonalIncome, setSelfEmployedPersonalIncome] =
        useState({});
    const [otherIncomeType, setOtherIncomeType] = useState({});
    const [personalProperties, setPersonalProperties] = useState([]);
    const [spouseProperties, setSpouseProperties] = useState([]);
    const [personalVehicles, setPersonalVehicles] = useState([]);
    const [spouseVehicles, setSpouseVehicles] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [activeItem, setActiveItem] = useState('');
    const [investments, setInvestments] = useState({ self: [], spouse: [] });

    const changeHandler = (activeItem) => setActiveItem(activeItem);

    useEffect(() => {
        const finalStatus = async () => {
            try {
                const req = await api.get(endpoints.FINAL_REVIEW);
                const res = await req.data;
                const {
                    perosnal_and_spouse_info,
                    household_info,
                    linked_bank_accounts,
                    employement_and_income_info,
                    assets_and_investment_info,
                    additional_info,
                    investment_info,
                } = res;
                setInvestments(investment_info);
                setAdditionalInfo(additional_info);
                setPersonalInfo({
                    name:
                        perosnal_and_spouse_info.self.first_name +
                        ' ' +
                        perosnal_and_spouse_info.self.last_name,
                    address:
                        perosnal_and_spouse_info.self.addresses[0].zip_code +
                        ',' +
                        ' ' +
                        perosnal_and_spouse_info.self.addresses[0].city +
                        ' ' +
                        perosnal_and_spouse_info.self.addresses[0].state,
                    dob: perosnal_and_spouse_info.self.dob,
                    email: perosnal_and_spouse_info.self.email
                        ? perosnal_and_spouse_info.self.email
                        : '',
                    home_phone: perosnal_and_spouse_info.self.home_phone
                        ? perosnal_and_spouse_info.self.home_phone
                        : '',
                    mobile_phone: perosnal_and_spouse_info.self.phone
                        ? perosnal_and_spouse_info.self.phone
                        : '',
                });
                if (res.spouse_detail) {
                    setSpouseDetail(res.spouse_detail);
                }
                if (res.marriage_date) {
                    setExtraInfo({
                        marriage_date: res.marriage_date,
                    });
                }
                if (
                    res.filing_status === STATUS_MARRIED ||
                    res.filing_status === STATUS_WIDOW_WITH_DEPENDENT ||
                    (res.filing_status === STATUS_MARRIED_SEPARATELY &&
                        res.spouse_detail === true)
                ) {
                    setSpouseInfo({
                        name: perosnal_and_spouse_info.spouse
                            ? perosnal_and_spouse_info.spouse.first_name +
                            ' ' +
                            perosnal_and_spouse_info.spouse.last_name
                            : '',
                        address: perosnal_and_spouse_info.spouse
                            ? perosnal_and_spouse_info.spouse.addresses[0]
                                .zip_code +
                            ',' +
                            ' ' +
                            perosnal_and_spouse_info.spouse.addresses[0]
                                .city +
                            ' ' +
                            perosnal_and_spouse_info.spouse.addresses[0].state
                            : 'No Data Available',
                        spouse_death_date: res.spouse_death_date
                            ? res.spouse_death_date
                            : '',
                        dob: perosnal_and_spouse_info.spouse
                            ? perosnal_and_spouse_info.spouse.dob
                            : '',
                        email: perosnal_and_spouse_info.spouse
                            ? perosnal_and_spouse_info.spouse.email
                            : '',
                        home_phone: perosnal_and_spouse_info.spouse
                            ? perosnal_and_spouse_info.spouse.home_phone
                            : '',
                        mobile_phone: perosnal_and_spouse_info.spouse
                            ? perosnal_and_spouse_info.spouse.phone
                            : '',
                    });
                }

                if (household_info && household_info.dependends.length > 0) {
                    household_info.dependends.map((opt) => {
                        return setHouseholdInfo((prevState) => [
                            ...prevState,
                            {
                                dependent: opt.name,
                                dob: opt.dob,
                                relationship: opt.relationship,
                                claim_on_tax_year: opt.claim_on_tax_year,
                                has_claim_on_tax_return: opt.has_claim_on_tax_return,
                            },
                        ]);
                    });
                }
                setPersonalBankAccounts(
                    linked_bank_accounts.self.map((opt) => {
                        return {
                            bank: opt.name,
                            bank_icon: opt.icon,
                            type: opt.type ? opt.type : '',
                            account_number: opt.acc_number
                                ? opt.acc_number
                                : 'Not Found',
                        };
                    })
                );
                if (
                    res.filing_status === STATUS_MARRIED ||
                    (res.filing_status === STATUS_MARRIED_SEPARATELY &&
                        res.spouse_detail === true)
                ) {
                    setSpouseBankAccounts(
                        linked_bank_accounts.spouse.map((opt) => {
                            return {
                                bank: opt.name,
                                bank_icon: opt.icon,
                                type: opt.type ? opt.type : '',
                                account_number: opt.acc_number
                                    ? opt.acc_number
                                    : 'Not Found',
                            };
                        })
                    );
                }
                setPersonalEmploymentIncome({
                    employer:
                        employement_and_income_info.employment_info.self
                            .employer.choice_name !==
                            'My Employer Was Not Found'
                            ? employement_and_income_info.employment_info.self
                                .employer.choice_name
                            : employement_and_income_info.employment_info.self
                                .employer.employer_information.business_name,
                    occupation: employement_and_income_info.employment_info.self
                        .employer.employer_information.occupation
                        ? employement_and_income_info.employment_info.self
                            .employer.employer_information.occupation
                        : '',
                    reported_date: employement_and_income_info.employment_info
                        .self.employer.employer_information.reported_date
                        ? employement_and_income_info.employment_info.self
                            .employer.employer_information.reported_date
                        : '',
                    address: employement_and_income_info.employment_info.self
                        .employer.employer_information.address
                        ? employement_and_income_info.employment_info.self
                            .employer.employer_information.address.data
                        : '',
                    business_name: employement_and_income_info.employment_info
                        .self.employer.employer_information.business_name
                        ? employement_and_income_info.employment_info.self
                            .employer.employer_information.business_name
                        : 'No Employer Found',
                    monthly_income: employement_and_income_info.employment_info
                        .self.estimated_monthly_income.amount
                        ? toUsCurrency(
                            employement_and_income_info.employment_info.self
                                .estimated_monthly_income.amount
                        )
                        : '',
                    net_amount: employement_and_income_info.employment_info
                        .self.estimated_monthly_income.net_amount
                        ? toUsCurrency(
                            employement_and_income_info.employment_info.self
                                .estimated_monthly_income.net_amount
                        )
                        : '',
                    additional_income: employement_and_income_info
                        .employment_info.self.additional_income.amount
                        ? employement_and_income_info.employment_info.self
                            .additional_income.amount
                        : '',
                });
                if (
                    res.filing_status === STATUS_MARRIED ||
                    (res.filing_status === STATUS_MARRIED_SEPARATELY &&
                        res.spouse_detail === true)
                ) {
                    setSpouseEmploymentIncome({
                        employer:
                            employement_and_income_info.employment_info.spouse
                                .employer.choice_name !==
                                'My Employer Was Not Found'
                                ? employement_and_income_info.employment_info
                                    .spouse.employer.choice_name
                                : employement_and_income_info.employment_info
                                    .spouse.employer.employer_information
                                    .business_name,
                        occupation: employement_and_income_info.employment_info
                            .spouse.employer.employer_information.occupation
                            ? employement_and_income_info.employment_info.spouse
                                .employer.employer_information.occupation
                            : '',
                        reported_date: employement_and_income_info
                            .employment_info.spouse.employer
                            .employer_information.reported_date
                            ? employement_and_income_info.employment_info.spouse
                                .employer.employer_information.reported_date
                            : '',
                        address: employement_and_income_info.employment_info
                            .spouse.employer.employer_information.address
                            ? employement_and_income_info.employment_info.spouse
                                .employer.employer_information.address.data
                            : '',
                        business_name: employement_and_income_info
                            .employment_info.spouse.employer
                            .employer_information.business_name
                            ? employement_and_income_info.employment_info.spouse
                                .employer.employer_information.business_name
                            : 'No Employer Found',
                        monthly_income: employement_and_income_info
                            .employment_info.spouse.estimated_monthly_income
                            .amount
                            ? toUsCurrency(
                                employement_and_income_info.employment_info
                                    .spouse.estimated_monthly_income.amount
                            )
                            : '',
                        net_amount: employement_and_income_info
                            .employment_info.spouse.estimated_monthly_income
                            .net_amount
                            ? toUsCurrency(
                                employement_and_income_info.employment_info
                                    .spouse.estimated_monthly_income.net_amount
                            )
                            : '',
                        additional_income: employement_and_income_info
                            .employment_info.spouse.additional_income.amount
                            ? employement_and_income_info.employment_info.spouse
                                .additional_income.amount
                            : '',
                    });
                }

                if (
                    additional_info.self.income_type === '1099' ||
                    additional_info.self.income_type === 'both' ||
                    ((res.filing_status === STATUS_MARRIED ||
                        (res.filing_status === STATUS_MARRIED_SEPARATELY &&
                            res.spouse_detail === true)) &&
                        (additional_info.spouse.income_type === '1099' ||
                            additional_info.spouse.income_type === 'both'))
                ) {
                    setSelfEmployedPersonalIncome(
                        employement_and_income_info.self_employed_info
                    );
                }
                if (
                    additional_info.self.income_type === 'Unemployed' ||
                    additional_info.self.income_type === 'Retired' ||
                    ((res.filing_status === STATUS_MARRIED ||
                        (res.filing_status === STATUS_MARRIED_SEPARATELY &&
                            res.spouse_detail === true)) &&
                        (additional_info.spouse.income_type === 'Unemployed' ||
                            additional_info.spouse.income_type === 'Retired'))
                ) {
                    setOtherIncomeType(
                        employement_and_income_info.employment_info
                    );
                }
                assets_and_investment_info.self.properties.map((opt) => {
                    return setPersonalProperties((prevState) => [
                        ...prevState,
                        {
                            address: opt.address
                                ? opt.address.data
                                : opt.other_address.data,
                            market_value: opt.market_value
                                ? toUsCurrency(opt.market_value)
                                : 'None',
                            outstanding_balance: opt.outstanding_balance
                                ? toUsCurrency(opt.outstanding_balance)
                                : '',
                            source_details: opt.source_details
                                ? opt.source_details
                                : '',
                            source: opt.source,
                            ownership_status: opt.ownership_status,
                            second_mortgages: opt.second_mortgages ? opt.second_mortgages : [],
                        },
                    ]);
                });
                if (
                    (res.filing_status === STATUS_MARRIED ||
                        (res.filing_status === STATUS_MARRIED_SEPARATELY &&
                            res.spouse_detail === true)) &&
                    assets_and_investment_info.spouse
                ) {
                    assets_and_investment_info.spouse.properties.map((opt) => {
                        return setSpouseProperties((prevState) => [
                            ...prevState,
                            {
                                address: opt.address
                                    ? opt.address.data
                                    : opt.other_address.data,
                                market_value: opt.market_value
                                    ? toUsCurrency(opt.market_value)
                                    : 'None',
                                outstanding_balance: opt.outstanding_balance
                                    ? toUsCurrency(opt.outstanding_balance)
                                    : '',
                                source_details: opt.source_details
                                    ? opt.source_details
                                    : '',
                                source: opt.source,
                                ownership_status: opt.ownership_status,
                                second_mortgages: opt.second_mortgages ? opt.second_mortgages : [],
                            },
                        ]);
                    });
                }

                assets_and_investment_info.self.vehicles.map((opt) => {
                    return setPersonalVehicles((prevState) => [
                        ...prevState,
                        {
                            vehicle_info: {
                                year: opt.year,
                                make: opt.make,
                                model: opt.model,
                                odometer: opt.odometer.toLocaleString(),
                            },
                            market_value: opt.market_value
                                ? opt.market_value
                                : 'None',
                            monthly_payment: opt.monthly_payment
                                ? toUsCurrency(opt.monthly_payment)
                                : 'None',
                            source: opt.source,
                            ownership_status: opt.ownership_status,
                        },
                    ]);
                });
                if (
                    (res.filing_status === STATUS_MARRIED ||
                        (res.filing_status === STATUS_MARRIED_SEPARATELY &&
                            res.spouse_detail === true)) &&
                    assets_and_investment_info.spouse
                ) {
                    assets_and_investment_info.spouse.vehicles.map((opt) => {
                        return setSpouseVehicles((prevState) => [
                            ...prevState,
                            {
                                vehicle_info: {
                                    year: opt.year,
                                    make: opt.make,
                                    model: opt.model,
                                    odometer: opt.odometer.toLocaleString(),
                                },
                                market_value: opt.market_value
                                    ? opt.market_value
                                    : 'None',
                                monthly_payment: opt.monthly_payment
                                    ? toUsCurrency(opt.monthly_payment)
                                    : 'None',
                                source: opt.source,
                                ownership_status: opt.ownership_status,
                            },
                        ]);
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };
        setSpouseProperties([]);
        setPersonalProperties([]);
        setSpouseVehicles([]);
        setPersonalVehicles([]);
        setPersonalBankAccounts([]);
        setSpouseBankAccounts([]);
        setHouseholdInfo([]);
        finalStatus();
    }, [filingStatus]);

    const goStart = async () => {
        try {
            const req = await api.post(endpoints.FINAL_STATUS);
            const res = await req.data;
            if (res) {
                authDispatch({
                    type: 'LOGOUT',
                    payload: {
                        access_token: null,
                        refresh_token: null,
                    },
                });
                setDisabled(true);
                setTimeout(
                    (window.location.href = `${process.env['REACT_APP_REDIRECT_URL']}`),
                    1000
                );
            }
        } catch (error) {
            setDisabled(false);
            console.log(error);
        }
    };

    return (
        <Fragment>
            <Fragment>
                <div className="mb-40">
                    <h1 className="mb-13">
                        Thank you for your help! Does This Look Correct?
                    </h1>
                    <p className="mb-40">
                        Please click the arrows to expand each section to review
                        your information.
                    </p>
                    <div className="bx--grid bx--no-gutter">
                        <div className="bx--row--off">
                            <Accordion>
                                <AccordionItem
                                    title={
                                        filingStatus === STATUS_MARRIED ||
                                            filingStatus ===
                                            STATUS_MARRIED_SEPARATELY
                                            ? 'Personal & Spouse Info Verification'
                                            : 'Personal Info Verification'
                                    }
                                    open={activeItem === 'title1'}
                                    onClick={changeHandler.bind(null, 'title1')}
                                >
                                    <PersonalInfo
                                        spouseDetail={spouseDetail}
                                        personal={personalInfo}
                                        spouse={spouseInfo}
                                        filing={filingStatus}
                                        extraInfo={extraInfo}
                                        spouseInfo={spouseInfo}
                                    />
                                </AccordionItem>

                                <AccordionItem
                                    title="Dependent Info"
                                    open={activeItem === 'title2'}
                                    onClick={changeHandler.bind(null, 'title2')}
                                >
                                    <HouseHoldInfo data={householdInfo} />
                                </AccordionItem>

                                <AccordionItem
                                    title="Linked Bank Accounts"
                                    open={activeItem === 'title3'}
                                    onClick={changeHandler.bind(null, 'title3')}
                                >
                                    <BankAccounts
                                        personalInfo={personalInfo}
                                        personal={personalBankAccounts}
                                        spouse={spouseBankAccounts}
                                        spouseDetail={spouseDetail}
                                        filing={filingStatus}
                                    />
                                </AccordionItem>

                                <AccordionItem
                                    title="Employment, Income & Expenses"
                                    open={activeItem === 'title4'}
                                    onClick={changeHandler.bind(null, 'title4')}
                                >
                                    <EmployedIndex
                                        personal={personalEmploymentIncome}
                                        spouse={spouseEmploymentIncome}
                                        selfEmployed={
                                            selfEmployedPersonalIncome
                                        }
                                        spouseDetail={spouseDetail}
                                        filing={filingStatus}
                                        additionalInfo={additionalInfo}
                                        personalInfo={personalInfo}
                                        spouseInfo={spouseInfo}
                                        otherIncome={otherIncomeType}
                                    />
                                </AccordionItem>

                                <AccordionItem
                                    title="Asset & Investment Info"
                                    open={activeItem === 'title5'}
                                    onClick={changeHandler.bind(null, 'title5')}
                                >
                                    <AssetInvestmentInfo
                                        personalProperties={personalProperties}
                                        personalVehicles={personalVehicles}
                                        spouseProperties={spouseProperties}
                                        spouseVehicles={spouseVehicles}
                                        spouseDetail={spouseDetail}
                                        filing={filingStatus}
                                        personalInfo={personalInfo}
                                        spouseInfo={spouseInfo}
                                        additionalInfo={additionalInfo}
                                        investments={investments}
                                    />
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
                <Navigation
                    onNext={goStart}
                    disablePrev={disabled}
                    disableNext={disabled}
                    nextText={'Submit'}
                    onPrevious={() => history.push(previousStep.path)}
                />
            </Fragment>
        </Fragment>
    );
}

export default IncomeSummary;
