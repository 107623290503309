import { useEffect, useState, useContext, Fragment } from 'react';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import api, { endpoints } from 'api';
import { LinkButton } from 'components/Buttons';
import { useStepController, useModal } from 'hooks';
import { history } from 'routes';
import Modal from 'components/Modal';
import Navigation from 'components/Navigation';
import { PersonalInfoContext } from 'contexts';
import BankInformation from './BankInformartion';
import { parseAmount } from 'lib';
import { MAX_AMOUNT,
    MAX_AMOUNT_US,
    MIN_ADDRESS_CHARS,
    MAX_BANK_NAME,
    MAX_ACCOUNT_NUMBER
} from 'const';
import {
    enableDotAmountCheck,
    enableMinAmountCheck,
    enableMaxAmountCheck,
} from 'lib/yupValidMethods';

const initManualBank = {
    profile: '',
    source: '',
    balance: '',
    bank_name: '',
    bank_icon: null,
    acc_numbers: "",
    bank_source: '',
    bank_type: '',
    uid: '',
};

function LinkManualBanks({ spouse, bank_account_type}) {
    const [initialValues, updateInitial] = useState({
        bankInformation: [Object.assign({}, initManualBank)],
    });

    const [delBankInfo, setDeleteBankInformation] = useState([]);

    const { personalUid, spouseUid } = useContext(PersonalInfoContext);

    const { previousStep, nextStep } = useStepController();
    const [open, showModal, hideModal] = useModal();

    useEffect(() => {
        // Fetch existing dependents
        const fetchExistBankInformation = async (profile) => {
            if (!profile) return;

            try {
                const req = await api({
                    url: endpoints.CONNECTED_BANK_INFO,
                    params: {
                        profile,
                        manual_input_screen: bank_account_type === "personal" ? 0 : 1
                    },
                });
                const res = await req.data;
                if (Array.isArray(res) && res.length) {
                    const existingBanks = res.map((data) => ({
                        balance: parseAmount(data.balance),
                        bank_name: data.bank_name,
                        bank_icon: data.bank_icon,
                        bank_source: data.bank_source,
                        bank_type: data.bank_type,
                        acc_numbers: data.acc_numbers !== null ? data.acc_numbers[0] : '',
                        uid: data.uid,
                    }));

                    updateInitial({ bankInformation: existingBanks });
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        fetchExistBankInformation(spouse ? spouseUid : personalUid);
    }, [personalUid, spouse, spouseUid, bank_account_type]);

    const saveBankInformation = async (values) => {
        try {
            const manualBanks = values.bankInformation.map((manualBank) => ({
                profile: spouse ? spouseUid : personalUid,
                balance: parseAmount(manualBank.balance),
                bank_name: manualBank.bank_name,
                bank_icon: manualBank.bank_icon,
                bank_source: manualBank.bank_source,
                bank_type: manualBank.bank_type,
                acc_numbers: manualBank.acc_numbers !== "" ? [manualBank.acc_numbers] : null,
                uid: manualBank.uid ? manualBank.uid : '',
                manual_input_screen: bank_account_type === "personal" ? 0 : 1
            }));

            const req = await api.post(
                endpoints.CONNECTED_BANK_INFO,
                manualBanks
            );
            const res = await req.data;

            if (res) {
                history.push(nextStep.path);
            }
        } catch (err) {
            console.log(err);
            console.log(err.response);
        }
    };

    const handleClear = (index, method, uid) => {
        setDeleteBankInformation([index, method, uid]);
        showModal();
    };

    const deleteBankInformation = async () => {
        const [index, remove, uid] = delBankInfo;

        // Delete banks from backend when uid available otherwise remove from the design
        if (!uid) {
            remove(index);
        } else {
            try {
                const deleteReq = await api({
                    method: 'delete',
                    url: `${endpoints.CONNECTED_BANK_INFO}${uid}/`,
                });

                if (deleteReq) remove(index);
            } catch (e) {
                console.log(e.response);
            }
        }
    };

    const goBack = () => {
        history.push(previousStep.path);
    };

    return (
        <Formik
            displayName="Bank Form"
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationRules}
            validateOnMount
            onSubmit={saveBankInformation}
        >
            {({ handleSubmit, values, isValid }) => (
                <Fragment>
                    <h1>
                        Please add your{' '}
                        <span className="color-blue">
                            {spouse && " spouse's"}{' '}
                        </span>
                        bank accounts.
                    </h1>

                    {spouse ? (
                        <p>
                            Use the add bank account button to add as many bank
                            accounts as your's spouse have.
                        </p>
                    ) : (
                        <p>
                            Use the add bank account button to add as many bank
                            accounts as you have.
                        </p>
                    )}

                    <FieldArray
                        name="bankInformation"
                        render={({ push, remove }) => (
                            <div className="mt-32">
                                <div>
                                    {values.bankInformation.map(
                                        ({ uid }, i) => (
                                            <BankInformation
                                                values={values}
                                                key={i}
                                                index={i}
                                                onClear={handleClear.bind(
                                                    null,
                                                    i,
                                                    remove,
                                                    uid
                                                )}
                                            />
                                        )
                                    )}
                                </div>

                                <LinkButton
                                    type="button"
                                    onClick={() => push(initManualBank)}
                                    className="mb-32"
                                >
                                    <span>Add Another Bank Account</span>
                                </LinkButton>
                            </div>
                        )}
                    />

                    <Navigation
                        onPrevious={goBack}
                        onNext={handleSubmit}
                        disableNext={!isValid}
                        typeNext="submit"
                    />

                    <Modal
                        open={open}
                        onClose={hideModal}
                        onSave={deleteBankInformation}
                        modalHeading="Are you sure you want to remove this Bank Information?"
                        primaryButtonText="Yes"
                        danger
                    >
                        <p>The information you entered will not be saved.</p>
                    </Modal>
                </Fragment>
            )}
        </Formik>
    );
}

enableDotAmountCheck();
enableMinAmountCheck();
enableMaxAmountCheck();

const validationRules = Yup.object().shape({
    bankInformation: Yup.array().of(
        Yup.object().shape({
            bank_name: Yup.string()
                .required('Please Enter Institution Name')
                .min(MIN_ADDRESS_CHARS, 'Minimum 2 characters')
                .max(MAX_BANK_NAME, 'Maximum 64 characters'),
            bank_source: Yup.string().required('Please Select Account Type'),
            bank_type: Yup.string().required('Please Select Bank Type'),
            balance: Yup.string()
                .required('Please Enter Balance')
                .onlyDot('Balance is not valid')
                .maxAmount(MAX_AMOUNT, `Maximum Balance can be ${MAX_AMOUNT_US}`),
            acc_numbers: Yup.string()
                .nullable()
                .max(MAX_ACCOUNT_NUMBER, `Maximum length can be ${MAX_ACCOUNT_NUMBER}`),
        })
    ),
});

export default LinkManualBanks;
