import { useEffect, useState, useContext } from 'react';
import { FormGroup } from 'carbon-components-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Input, FormattedInput, TermsCheckbox } from 'components/Form';
import api, { endpoints } from 'api';
import Modal from 'components/Modal';
import { PersonalInfoContext, contextActionTypes } from 'contexts';
import { history } from 'routes';
import { useModal, useStepController } from 'hooks';
import {
    enableDateFormatMethod,
    enableZipCodeValidateMethod,
    enableFutuerDateMethod,
    enablePhoneValidateMethod,
    enableMinDateCheck,
} from '../../lib/yupValidMethods';
import { AddressBlock } from 'components/Utilities';
import {
    DATE_PATTERN,
    DATE_FORMAT,
    MIN_NAME_CHARS,
    MAX_NAME_CHARS,
    MIN_ADDRESS_CHARS,
    MAX_ADDRESS_CHARS,
    MIN_DATE,
    EMAIL_REGEX,
} from 'const';
import Terms from './Terms';
import AlertTerms from './AlertTerms';
import { isArrayEmpty, removeCountryCodeFromPhone } from 'lib';
import Navigation from 'components/Navigation';
import styled from 'styled-components';

const initialValues = {
    first_name: '',
    last_name: '',
    dob: '',
    addresses: [
        {
            line_1: '',
            line_2: '',
            zip_code: '',
            city: '',
            state: '',
        },
    ],
    ssn: '',
    agree_terms: true,
    email: '',
    phone: '',
    home_phone: '',
    call_credit_report: true,
};

function PersonalInfoVerification() {
    const [error, setError] = useState('');
    const [errorModal, setErrorModal] = useState(false);
    const [addressModal, setAddressModal] = useState(false);
    const [initialProfile, updateInitialProfile] = useState(initialValues);
    const { personalInfo, dispatchPersonal, personalUid } = useContext(
        PersonalInfoContext
    );
    const { nextStep, previousStep } = useStepController();
    const [open, showAgreeAlert, hideAgreeAlert] = useModal();
    const [modalOpen, showModal, hideModal] = useModal();

    useEffect(() => {
        updatePersonalInfo(personalInfo);
    }, [personalInfo]);

    const updatePersonalInfo = (values) => {
        const {
            first_name,
            last_name,
            ssn,
            addresses,
            dob,
            email,
            phone,
            home_phone,
        } = values;

        updateInitialProfile({
            first_name,
            last_name,
            ssn,
            dob,
            email,
            home_phone: home_phone
                ? removeCountryCodeFromPhone(home_phone)
                : '',
            phone: phone ? removeCountryCodeFromPhone(phone) : '',
            addresses: isArrayEmpty(addresses)
                ? addresses
                : [
                    {
                        line_1: addresses[0].line_1,
                        line_2: addresses[0].line_2,
                        zip_code: addresses[0].zip_code,
                        city: addresses[0].city,
                        state: addresses[0].state,
                    },
                ],
            agree_terms: true,
            call_credit_report: true,
        });
    };

    const savePersonalInfo = async (values) => {
        updatePersonalInfo(values);
        personalInfoApiUpdate(values);
    };

    const personalInfoApiUpdate = async (values, data) => {
        if (data === 'addressConfirm') {
            values['call_credit_report'] = false;
        }
        if (!values.agree_terms) {
            showAgreeAlert();
        } else {
            try {
                const data = { ...values };
                let url = endpoints.PERSONAL_PROFILE;
                if (personalUid) url += `${personalUid}/`;
                const req = await api({
                    method: 'patch',
                    url,
                    data,
                    disableBadReqGlobalError: true,
                });
                const res = req.data;

                if (res) {
                    dispatchPersonal({
                        type: contextActionTypes.STORE_PERSONAL_INFO,
                        payload: res,
                    });

                    history.push(nextStep.path);
                }
            } catch (e) {
                if (e.response.data.experian) {
                    const errorData = JSON.parse(e.response.data.experian);
                    if (errorData.errors[0].message.indexOf('address') !== -1) {
                        setAddressModal(true);
                    } else {
                        // string replacement done because experian provide wrong params for first & last mane
                        const conditionsKey = ['surname', 'lastName'];
                        const keyExist = conditionsKey.some((data) =>
                            errorData.errors[0].message
                                .split(' ')
                                .includes(data)
                        );
                        let replaceText;
                        keyExist
                            ? (replaceText = 'firstName')
                            : (replaceText = 'lastName');
                        setError(
                            errorData.errors[0].message
                                .replace('lastName', replaceText)
                                .replace('surname', replaceText)
                                .replace('firstName', replaceText)
                        );
                        setErrorModal(true);
                    }
                } else {
                    setError(e.response.data.detail.errors[0].message);
                    setErrorModal(true);
                }
                showModal();
            }
        }
    };

    const addressConfirmation = async () => {
        personalInfoApiUpdate(initialProfile, 'addressConfirm');
    };

    const errorMessage = (
        <Modal
            open={modalOpen}
            onClose={hideModal}
            modalHeading="Error Message"
            singleButton
        >
            <ModalMessage>{error}</ModalMessage>
        </Modal>
    );

    const addressConfirmationModal = (
        <Modal
            open={modalOpen}
            onClose={hideModal}
            onSave={addressConfirmation}
            modalHeading="We weren't able to verify your home address. Is the address correct?"
            primaryButtonText="Yes"
        >
            <p>Would you like to use this address anyway</p>
        </Modal>
    );

    return (
        <Formik
            initialValues={initialProfile}
            onSubmit={savePersonalInfo}
            validationSchema={validation}
            enableReinitialize={true}
            validateOnMount={true}
        >
            {({ handleSubmit, isValid, values }) => (
                <form>
                    <h1>
                        Please Verify{' '}
                        <span className="color-blue"> your information</span>
                    </h1>
                    <p>
                        Please take a moment to review the following information
                        for completeness and accuracy.
                    </p>

                    {errorModal && errorMessage}
                    {addressModal && addressConfirmationModal}

                    <section className="py-24">
                        <h2 className="mb-16">Personal Information</h2>

                        <FormGroup legendText="">
                            <Input
                                id="f-name"
                                name="first_name"
                                labelText="First Name"
                                placeholder=""
                            />
                        </FormGroup>

                        <FormGroup legendText="">
                            <Input
                                id="l-name"
                                name="last_name"
                                labelText="Last Name"
                                placeholder=""
                            />
                        </FormGroup>

                        <FormGroup legendText="">
                            <FormattedInput
                                labelText={'Date of Birth'}
                                formatOptions={{
                                    date: true,
                                    delimiter: '-',
                                    dateMin: MIN_DATE,
                                    datePattern: DATE_PATTERN,
                                }}
                                name="dob"
                                placeholder={DATE_FORMAT}
                            />
                        </FormGroup>

                        <AddressBlock
                            name={{
                                address: 'addresses[0].line_1',
                                address2: 'addresses[0].line_2',
                                city: 'addresses[0].city',
                                state: 'addresses[0].state',
                                zipCode: 'addresses[0].zip_code',
                            }}
                        />

                        <FormGroup legendText="">
                            <FormattedInput
                                id="ssn"
                                name="ssn"
                                labelText="SSN"
                                formatOptions={{
                                    delimiter: '-',
                                    blocks: [3, 2, 4],
                                }}
                            />
                        </FormGroup>
                    </section>

                    <h2 className="mb-16">Contact Information</h2>

                    <section>
                        <FormGroup legendText="">
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                labelText="Email"
                                placeholder=""
                            />
                        </FormGroup>

                        <FormGroup legendText="">
                            <Input
                                id="office-phone"
                                name="phone"
                                labelText="Mobile Phone"
                                placeholder=""
                                addon="+1"
                            />
                        </FormGroup>

                        <FormGroup legendText="">
                            <Input
                                id="home-phone"
                                name="home_phone"
                                labelText="Home Phone"
                                placeholder=""
                                addon="+1"
                            />
                        </FormGroup>
                    </section>

                    <TermsCheckbox
                        name={'agree_terms'}
                        id={'agree'}
                        terms={
                            <Terms
                                first_name={values.first_name}
                                last_name={values.last_name}
                            />
                        }
                        className={'mb-55'}
                    />

                    <Navigation
                        onNext={handleSubmit}
                        disableNext={!isValid}
                        typeNext="submit"
                        onPrevious={() => history.push(previousStep.path)}
                    />

                    <AlertTerms open={open} hide={hideAgreeAlert} />
                </form>
            )}
        </Formik>
    );
}

// Add dateFormat custom validation
enableDateFormatMethod();
enableFutuerDateMethod();
enableMinDateCheck();
// Add zip code custom validation
enableZipCodeValidateMethod();
enablePhoneValidateMethod();

const validation = Yup.object().shape({
    first_name: Yup.string()
        .min(MIN_NAME_CHARS, 'Minimum 2 characters')
        .max(MAX_NAME_CHARS, 'Maximum 30 characters')
        .required('Required field'),
    last_name: Yup.string()
        .min(MIN_NAME_CHARS, 'Minimum 2 characters')
        .max(MAX_NAME_CHARS, 'Maximum 30 characters')
        .required('Required field'),
    email: Yup.string()
        .matches(EMAIL_REGEX, {
            excludeEmptyString: true,
            message: 'Invalid Email',
        })
        .required('Required field'),
    phone: Yup.string().required('Required field').phone(),
    home_phone: Yup.string().nullable().phone(),
    dob: Yup.string()
        .required('Required field')
        .nullable()
        .dateFormat()
        .minDate()
        .futureDate(),
    addresses: Yup.array().of(
        Yup.object().shape({
            line_1: Yup.string()
                .required('Required field')
                .min(MIN_ADDRESS_CHARS, 'Minimum 2 characters')
                .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
            line_2: Yup.string()
                .nullable()
                .min(MIN_ADDRESS_CHARS, 'Minimum 2 characters')
                .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
            city: Yup.string().required('Required field'),
            zip_code: Yup.string().required('Required field').zipCodeValidate(),
            state: Yup.string().required('Required field'),
        })
    ),
    ssn: Yup.string()
        .matches(/^(\d{3}-\d{2}|\*{3}-\*{2})-\d{4}$/, 'Wrong SSN format')
        .required('Required field'),
});

const ModalMessage = styled.p`
    min-height: 10vh;
    font-size: 1rem !important;
`;

export default PersonalInfoVerification;
