import { useEffect, useState, useContext, Fragment } from 'react';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import api, { endpoints } from 'api';
import { LinkButton } from 'components/Buttons';
import { useStepController, useModal } from 'hooks';
import { history } from 'routes';
import Modal from 'components/Modal';
import Navigation from 'components/Navigation';
import { PersonalInfoContext } from 'contexts';
import { parseAmount } from 'lib';
import {
    MAX_AMOUNT,
    MAX_AMOUNT_US,
    MIN_ADDRESS_CHARS,
    MAX_BANK_NAME,
    MAX_ACCOUNT_NUMBER,
} from 'const';
import {
    enableDotAmountCheck,
    enableMinAmountCheck,
    enableMaxAmountCheck,
} from 'lib/yupValidMethods';
import InvestmentForm from './InvestmentForm';

const initInvestment = {
    account_sub_type: '',
    institution_name: '',
    account_number: null,
    current_market_value: '0',
    loan_balance: '0',
    uid: '',
    withdrawable: '',
    name_of_broker: '',
    date_of_current_market_value: null,
};

const HEADING = (
    <h1>
        Do you have any{' '}
        <span className="color-blue">investment or retirement accounts?</span>
    </h1>
);

const HEADING_SPOUSE = (
    <h1>
        Does your <span className="color-blue">spouse</span> have
        <span className="color-blue">investment or retirement accounts?</span>
    </h1>
);

function AddInvestments({ spouse }) {
    const [initialValues, updateInitial] = useState({
        investmentAccounts: [Object.assign({}, initInvestment)],
    });

    const [delInvestmentInfo, setDeleteInvestmentInfo] = useState([]);

    const { personalUid, spouseUid } = useContext(PersonalInfoContext);

    const { previousStep, nextStep } = useStepController();
    const [open, showModal, hideModal] = useModal();

    useEffect(() => {
        // Fetch existing dependents
        const fetchInvestmentAccounts = async (profile) => {
            if (!profile) return;

            try {
                const req = await api({
                    url: endpoints.INVESTMENT_ACCOUNTS,
                    params: {
                        profile,
                    },
                });
                const res = await req.data;

                if (Array.isArray(res) && res.length) {
                    const existingAccounts = res.map((data) => ({
                        account_sub_type: data.account_sub_type,
                        institution_name: data.institution_name,
                        account_number: data.account_number,
                        current_market_value: parseAmount(
                            data.current_market_value
                        ),
                        loan_balance: parseAmount(data.loan_balance),
                        uid: data.uid,
                        date_of_current_market_value: data.date_of_current_market_value,
                        withdrawable: data.withdrawable,
                    }));

                    updateInitial({ investmentAccounts: existingAccounts });
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        fetchInvestmentAccounts(spouse ? spouseUid : personalUid);
    }, [personalUid, spouse, spouseUid]);

    const saveInvestmentAccounts = async (values) => {
        try {
            // console.log(values.investmentAccounts);
            const accounts = values.investmentAccounts.map((account) => ({
                ...account,
                profile: spouse ? spouseUid : personalUid,
                account_type: 'investment',
                current_market_value: parseAmount(account.current_market_value),
                loan_balance: parseAmount(account.loan_balance),
                uid: account.uid ? account.uid : '',
            }));

            const req = await api.post(endpoints.INVESTMENT_ACCOUNTS, accounts);
            const res = await req.data;

            if (res) {
                history.push(nextStep.path);
            }
        } catch (err) {
            console.log(err.response);
        }
    };

    const handleClear = (index, method, uid) => {
        setDeleteInvestmentInfo([index, method, uid]);
        showModal();
    };

    const deleteAccount = async () => {
        const [index, remove, uid] = delInvestmentInfo;

        // Delete banks from backend when uid available otherwise remove from the design
        if (!uid) {
            remove(index);
        } else {
            try {
                const deleteReq = await api({
                    method: 'delete',
                    url: `${endpoints.INVESTMENT_ACCOUNTS}${uid}/`,
                });

                if (deleteReq) remove(index);
            } catch (e) {
                console.log(e.response);
            }
        }
    };

    const goBack = () => {
        history.push(previousStep.path);
    };

    return (
        <Formik
            displayName="Investment Form"
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationRules}
            validateOnMount
            onSubmit={saveInvestmentAccounts}
        >
            {({ handleSubmit, values, isValid }) => (
                <Fragment>
                    {spouse ? HEADING_SPOUSE : HEADING}

                    <p>
                        This includes IRAs, 401k plans, stocks, bonds, and
                        mutual funds.
                    </p>

                    <FieldArray
                        name="investmentAccounts"
                        render={({ push, remove }) => (
                            <div className="mt-32">
                                <div>
                                    {values.investmentAccounts.map(
                                        ({ uid }, i) => (
                                            <InvestmentForm
                                                values={values}
                                                key={i}
                                                index={i}
                                                onClear={handleClear.bind(
                                                    null,
                                                    i,
                                                    remove,
                                                    uid
                                                )}
                                            />
                                        )
                                    )}
                                </div>

                                <LinkButton
                                    type="button"
                                    onClick={() => push(initInvestment)}
                                    className="mb-32"
                                >
                                    <span>Add Another Investment Account</span>
                                </LinkButton>
                            </div>
                        )}
                    />

                    <Navigation
                        onPrevious={goBack}
                        onNext={handleSubmit}
                        disableNext={!isValid}
                        typeNext="submit"
                    />

                    <Modal
                        open={open}
                        onClose={hideModal}
                        onSave={deleteAccount}
                        modalHeading="Are you sure you want to remove this Bank Information?"
                        primaryButtonText="Yes"
                        danger
                    >
                        <p>The information you entered will not be saved.</p>
                    </Modal>
                </Fragment>
            )}
        </Formik>
    );
}

enableDotAmountCheck();
enableMinAmountCheck();
enableMaxAmountCheck();

const validationRules = Yup.object().shape({
    investmentAccounts: Yup.array().of(
        Yup.object().shape({
            institution_name: Yup.string()
                .when('account_sub_type', {
                    is: (account_sub_type) => account_sub_type != 'virtual_currency',
                    then: Yup.string()
                        .required('Please Enter Institution Name')
                        .min(MIN_ADDRESS_CHARS, 'Minimum 2 characters')
                        .max(MAX_BANK_NAME, 'Maximum 64 characters'),
                    otherwise: Yup.string(),
                }),
            account_sub_type: Yup.string().required(
                'Please Select Account Type'
            ),
            current_market_value: Yup.string()
                .required('Please Enter Market Value')
                .onlyDot('Value is not valid')
                .maxAmount(MAX_AMOUNT, `Maximum Value can be ${MAX_AMOUNT_US}`),
            loan_balance: Yup.string()
                .when('account_sub_type', {
                    is: (account_sub_type) => account_sub_type != 'virtual_currency',
                    then: Yup.string()
                        .required('Please Enter Loan Value')
                        .onlyDot('Value is not valid')
                        .maxAmount(MAX_AMOUNT, `Maximum Value can be ${MAX_AMOUNT_US}`),
                    otherwise: Yup.string(),
                }),
            account_number: Yup.string().nullable()
                .when('account_sub_type', {
                    is: (account_sub_type) => account_sub_type != 'virtual_currency',
                    then: Yup.string()
                        .required('Please Enter Account Number')
                        .max(
                            MAX_ACCOUNT_NUMBER,
                            `Maximum length can be ${MAX_ACCOUNT_NUMBER}`
                        ),
                    otherwise: Yup.string(),
                }),
            // name_of_broker: Yup.string()
            //     .when('account_sub_type', {
            //         is: (account_sub_type) => account_sub_type === 'virtual_currency',
            //         then: Yup.string()
            //             .required('Please Enter Broker Name')
            //             .min(MIN_ADDRESS_CHARS, 'Minimum 2 characters')
            //             .max(MAX_BANK_NAME, 'Maximum 64 characters'),
            //         otherwise: Yup.string(),
            //     }),
            date_of_current_market_value: Yup.string().nullable()
                .when('account_sub_type', {
                    is: (account_sub_type) => account_sub_type === 'virtual_currency',
                    then: Yup.string()
                        .required('Please Enter the Date of Market Value')
                        .nullable()
                        .dateFormat()
                        .minDate()
                        .futureDate(),
                    otherwise: Yup.string(),
                }),
        })
    ),
});


export default AddInvestments;
