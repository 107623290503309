import { useState, useEffect, useContext } from 'react';
import { Dropdown, FormGroup } from 'carbon-components-react';
import {
    GOOGLE_MAP_API_KEY,
    DATE_PATTERN,
    DATE_FORMAT,
    MIN_ADDRESS_CHARS,
    MAX_ADDRESS_CHARS,
} from 'const';
import {
    enableDateFormatMethod,
    enableZipCodeValidateMethod,
    enableFutuerDateMethod,
    enableMinDateCheck,
} from 'lib/yupValidMethods';
import { PersonalInfoContext, contextActionTypes } from 'contexts';
import { FormattedInput, Input } from 'components/Form';
import { useStepController, useEmployersList } from 'hooks';
import api, { endpoints } from 'api';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { history } from 'routes';
import { AddressBlock } from 'components/Utilities';
import Navigation from 'components/Navigation';
import axios from 'axios';
import { formateAddressComponent } from 'lib';

const { CLIENT_EMPLOYER_NAME, SPOUSE_EMPLOYER_NAME } = contextActionTypes;

const initialValues = {
    occupation: '',
    reported_date: '',
    address: {
        line_1: '',
        line_2: '',
        zip_code: '',
        city: '',
        state: '',
    },
    business_name: '',
    choice_name: 'My Employer Was Not Found',
};

const emptyAddress = {
    line_1: '',
    line_2: '',
    city: '',
    state: '',
    zip_code: '',
};

const MANUAL_EMPLOYER = 'My Employer Was Not Found';

// Search address by company name using Google MAP
async function searchAddressByText(text) {
    if (!text) return {};

    try {
        const req = await axios({
            method: 'get',
            url: 'https://maps.googleapis.com/maps/api/geocode/json',
            params: {
                address: text,
                key: GOOGLE_MAP_API_KEY,
                locationbias: 'point:37.0902,95.7129',
            },
        });

        const res = await req.data;
        const results = await res.results;

        if (results && res.results.length) {
            const foundAddress = formateAddressComponent(res.results);

            return foundAddress;
        } else {
            return {};
        }
    } catch (e) {
        console.log(e.response);
    }
}

function WorkInformation({ spouse }) {
    const [employerList, updateEmployerList] = useState([]);
    const [employersInfo, setEmployersInfo] = useState([]);
    const [selectedEmployer, setSelectedEmployer] = useState({});
    const [initialEmployer, updateInitialEmployer] = useState(
        Object.assign({}, initialValues)
    );
    // To detect user doesn't have any employer from credit report
    const [hasNoEmployer, toggleNoEmployer] = useState(false);

    const { personalUid, spouseUid, dispatchEmployment } = useContext(
        PersonalInfoContext
    );

    const { nextStep, previousStep } = useStepController();
    // Employers from Credit Report
    const clientEmployers = useEmployersList(spouse ? spouseUid : personalUid);

    useEffect(() => {
        if (Array.isArray(clientEmployers) && clientEmployers.length) {
            setEmployersInfo(clientEmployers);

            updateEmployerList(
                clientEmployers.map((opt) => ({
                    id: opt.uid,
                    label: opt.choice_name,
                }))
            );

            // Assuming Employer was not found when there has only one result
            if (clientEmployers.length === 1) {
                setSelectedEmployer({
                    id: clientEmployers[0].uid,
                    label: clientEmployers[0].choice_name,
                });

                toggleNoEmployer(true);
            }

            const result = clientEmployers.find(
                (data) => data.verified === true
            );

            if (result) {
                const { uid, choice_name, employer_information } = result;

                setSelectedEmployer({
                    id: uid,
                    label: choice_name,
                });

                const { occupation, reported_date, address, business_name } =
                    employer_information || {};

                const { line_1, city, state, zip_code, line_2 } =
                    address || emptyAddress;

                // If Employer found at first then find address and update
                if (!address) findAddress(choice_name, false, true);

                updateInitialEmployer({
                    occupation,
                    reported_date,
                    address: {
                        line_1: line_1,
                        line_2: line_2,
                        zip_code: zip_code,
                        city: city,
                        state: state,
                    },
                    business_name,
                    choice_name: result.choice_name,
                });
            }
        }
    }, [clientEmployers]);

    async function findAddress(text, setFieldValue, updateInitial = false) {
        const address = await searchAddressByText(text);

        // Update form value
        if (setFieldValue) {
            setFieldValue('address', {
                line_1: address.address,
                city: address.city,
                state: address.state,
                zip_code: address.zipCode,
            });
        }

        // Update form initial value
        if (updateInitial) {
            updateInitialEmployer((initData) => ({
                ...initData,
                address: {
                    line_1: address.address,
                    city: address.city,
                    state: address.state,
                    zip_code: address.zipCode,
                },
            }));
        }
    }

    const handleChange = ({ selectedItem }, setValues, setFieldValue) => {
        setSelectedEmployer(selectedItem);

        const selectedEmployerData = employersInfo.find(
            (emp) => emp.uid === selectedItem.id
        );

        if (selectedEmployerData['employer_information']) {
            const {
                occupation,
                reported_date,
                address,
                business_name,
            } = selectedEmployerData['employer_information'];

            const { line_1, city, state, zip_code, line_2 } =
                address === null ? emptyAddress : address;

            setValues({
                occupation,
                reported_date,
                address: {
                    line_1,
                    line_2,
                    zip_code,
                    city,
                    state,
                },
                business_name,
                choice_name: selectedItem.label,
            });

            if (!line_1) {
                findAddress(selectedItem.label, setFieldValue);
            }
        } else {
            setValues({
                occupation: '',
                reported_date: '',
                address: {
                    line_1: '',
                    line_2: '',
                    zip_code: '',
                    city: '',
                    state: '',
                },
                business_name: '',
                choice_name: selectedItem.label,
            });
        }
    };

    const updateEmployer = async (values) => {
        const { occupation, address, reported_date, business_name } = values;
        const data = {
            verified: true,
            employer_information: {
                occupation,
                reported_date,
                address,
            },
        };

        if (selectedEmployer.label === MANUAL_EMPLOYER) {
            data.employer_information['business_name'] = business_name;
        }

        try {
            const req = await api.patch(
                endpoints.EMPLOYER_LIST + selectedEmployer.id + '/',
                data
            );
            const res = await req.data;

            if (res) {
                dispatchEmployment({
                    type: spouse ? SPOUSE_EMPLOYER_NAME : CLIENT_EMPLOYER_NAME,
                    payload: res.employer_information.business_name,
                });
                history.push(nextStep.path);
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    return (
        <Formik
            initialValues={initialEmployer}
            onSubmit={updateEmployer}
            validationSchema={inputValidate}
            validateOnMount
            enableReinitialize={true}
        >
            {({
                handleSubmit,
                isValid,
                setValues,
                setFieldValue,
                handleChange: formChangeHandler,
                values,
            }) => (
                <form>
                    <h2 className="mb-32">
                        Please enter your {spouse ? "spouse's" : ''}{' '}employment{' '}
                        <span className="color-blue">information.</span>
                    </h2>

                    {!hasNoEmployer && (
                        <FormGroup legendText="">
                            <Dropdown
                                ariaLabel="Dropdown"
                                id="carbon-dropdown-example"
                                items={employerList}
                                label="Choose your employer"
                                onChange={(selection) =>
                                    handleChange(
                                        selection,
                                        setValues,
                                        setFieldValue
                                    )
                                }
                                titleText="Employer’s Name"
                                selectedItem={selectedEmployer}
                            />
                        </FormGroup>
                    )}

                    {/* To make business name required when employer was not found */}
                    <input
                        type="text"
                        name="choice_name"
                        value={values.choice_name}
                        onChange={formChangeHandler}
                        readOnly
                        disabled
                        className="hide-input"
                    />

                    {selectedEmployer.label === MANUAL_EMPLOYER ||
                    hasNoEmployer ? (
                        <FormGroup legendText="">
                            <Input
                                id="business_name"
                                name="business_name"
                                labelText={`${
                                    hasNoEmployer ? '' : 'Manual '
                                }Employer's Name`}
                            />
                        </FormGroup>
                    ) : null}

                    <AddressBlock />

                    <FormGroup legendText="">
                        <Input
                            type="text"
                            id="occupation"
                            name="occupation"
                            labelText="Occupation"
                            placeholder="Enter You Occupation"
                        />
                    </FormGroup>

                    <FormGroup legendText="">
                        <FormattedInput
                            labelText={'Start Date'}
                            formatOptions={{
                                date: true,
                                delimiter: '-',
                                datePattern: DATE_PATTERN,
                            }}
                            name="reported_date"
                            placeholder={DATE_FORMAT}
                        />
                    </FormGroup>

                    <Navigation
                        onPrevious={() => history.push(previousStep.path)}
                        onNext={handleSubmit}
                        disableNext={!isValid}
                        typeNext={'submit'}
                    />
                </form>
            )}
        </Formik>
    );
}

enableDateFormatMethod();
enableFutuerDateMethod();
enableMinDateCheck();
enableZipCodeValidateMethod();

const inputValidate = Yup.object().shape({
    occupation: Yup.string()
        .nullable()
        .required('Please Enter Occupation')
        .test(
            'len',
            'Please provide valid occupation name',
            (val) => (val + '').length >= 2
        ),
    reported_date: Yup.string()
        .required('Start Date is required field')
        .dateFormat()
        .futureDate()
        .minDate(),
    address: Yup.object().shape({
        line_1: Yup.string()
            .required('Required field')
            .min(MIN_ADDRESS_CHARS, 'Minimum 2 characters')
            .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
        line_2: Yup.string()
            .nullable()
            .min(MIN_ADDRESS_CHARS, 'Minimum 2 characters')
            .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
        city: Yup.string().required('Required field'),
        state: Yup.string().required('Required field'),
        zip_code: Yup.string()
            .required('Zip Code Is Required field')
            .zipCodeValidate(),
    }),
    choice_name: Yup.string().nullable(),
    business_name: Yup.string()
        .nullable()
        .when(['choice_name'], {
            is: (choice_name) => choice_name === MANUAL_EMPLOYER,
            then: Yup.string().required("Please enter employer's name"),
        }),
});

export default WorkInformation;
