import { Fragment, useState, useContext, useEffect } from 'react';
import { FormGroup } from 'carbon-components-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from 'components/Form';
import { AddressBlock } from 'components/Utilities';
import Navigation from 'components/Navigation';
import { useStepController } from 'hooks';
import {
    SELF_PRIMARY_BUSINESS_KEY,
    MIN_ADDRESS_CHARS,
    MAX_ADDRESS_CHARS
} from 'const';
import { enableZipCodeValidateMethod } from 'lib/yupValidMethods';
import { PersonalInfoContext, contextActionTypes } from 'contexts';
import api, { endpoints } from 'api';
import { history } from 'routes';
import useBusinessInfo from './useBusinessInfo';

const initialForm = {
    business_name: '',
    address: {
        line_1: '',
        line_2: '',
        zip_code: '',
        city: '',
        state: '',
    },
};

const {
    CLIENT_EMPLOYER_NAME,
    SPOUSE_EMPLOYER_NAME,
} = contextActionTypes;

function BusinessInformation({ spouse }) {
    const [initialValues, updateInitialValues] = useState(initialForm);
    // Uid for updating business info (patch). Also work as a flag
    const [businessUid, setBusinessUid] = useState(null);

    const { personalUid, spouseUid, dispatchEmployment } = useContext(PersonalInfoContext);
    const { previousStep, nextStep } = useStepController();
    const { businessInfo } = useBusinessInfo(spouse ? spouseUid : personalUid);

    useEffect(() => {
        const { business_name, address, uid } = businessInfo;

        setBusinessUid(uid);

        updateInitialValues({
            business_name,
            address: {
                line_1: address ? address.line_1 : '',
                line_2: address ? address.line_2 : '',
                zip_code: address ? address.zip_code : '',
                city: address ? address.city : '',
                state: address ? address.state : '',
            },
        });
    }, [businessInfo]);

    const submit = async (values) => {
        values['profile'] = spouse ? spouseUid : personalUid;
        values['verified'] = true;
        values['self_employed_business_type'] = SELF_PRIMARY_BUSINESS_KEY;

        try {
            let url = endpoints.SELF_EMPLOYED_BUSINESS_INFO;
            if (businessUid) url += `${businessUid}/`;

            const req = await api({
                method: businessUid ? 'patch' : 'post',
                url,
                data: values,
            });

            const res = await req.data;

            if (res) {
                dispatchEmployment({
                    type: spouse
                        ? SPOUSE_EMPLOYER_NAME
                        : CLIENT_EMPLOYER_NAME,
                    payload: res.business_name,
                });
                history.push(nextStep.path);
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={submit}
            validationSchema={validation}
            validateOnMount={true}
        >
            {({ handleSubmit, isValid }) => (
                <Fragment>
                    <h1>
                        Now we need some{' '}
                        <span className="color-blue">
                            Information
                        </span> about {
                            spouse ? 'your' : 'the'
                        }
                        {spouse ? (
                            <span className="color-blue">spouse's</span>
                        ) : (
                            ''
                        )}{' '}
                        <span className="color-blue">
                            business.
                        </span>
                    </h1>
                    <section className="my-32">
                        <FormGroup legendText="">
                            <Input
                                id="business_name"
                                name="business_name"
                                labelText="Business Name"
                                placeholder=""
                            />
                        </FormGroup>
                        <AddressBlock />
                        <Navigation
                            onNext={handleSubmit}
                            onPrevious={() => history.push(previousStep.path)}
                            typeNext="submit"
                            disableNext={!isValid}
                        />
                    </section>
                </Fragment>
            )}
        </Formik>
    );
}

// Add zip code custom validation
enableZipCodeValidateMethod();

const validation = Yup.object().shape({
    business_name: Yup.string()
        .required('Required field')
        .test(
            'len',
            'Please provide valid business name',
            (val) => (val + '').length >= 2
        ),
    address: Yup.object().shape({
        line_1: Yup
            .string()
            .required('Required field')
            .min(MIN_ADDRESS_CHARS, 'Minimum 2 characters')
            .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
        line_2: Yup
            .string()
            .nullable()
            .min(MIN_ADDRESS_CHARS, 'Minimum 2 characters')
            .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
        city: Yup.string().required('Required field'),
        zip_code: Yup.string().required('Required field').zipCodeValidate(),
        state: Yup.string().required('Required field'),
    }),
});

export default BusinessInformation;
