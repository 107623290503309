import { Fragment, useEffect, useContext, useState, useMemo, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from 'components/Navigation';
import { FormGroup } from 'carbon-components-react';
import { InvestmentContext, contextActionTypes, PersonalInfoContext } from 'contexts';
import { useStepController, useLocalStorage } from 'hooks';
import { history } from 'routes';
import { toUsCurrency } from 'lib';
import { SwitchInput, MultiSelect } from 'components/Form';
import { Formik } from 'formik';
import { LoadGoogleMapScript } from 'components/GoogleMap';
import * as Yup from 'yup';
import api, { endpoints } from 'api';
import PropertyAddress from './PropertyAddress';
import {
    MAX_AMOUNT_PROPERTIES,
    MIN_ADDRESS_CHARS,
    MAX_ADDRESS_CHARS,
} from 'const';
import { AmountInput } from 'components/Utilities';
import {
    enableDotAmountCheck,
    enableMinAmountCheck,
    enableMaxAmountCheck,
} from 'lib/yupValidMethods';
import { parseAmount } from 'lib';

const initData = {
    verified: null,
    market_value: '',
    outstanding_balance: '',
    primary_residence: null,
    address: null,
    other_address: {},
    has_refinance_mortgage: false,
    second_mortgages: [],
    refinance_loan_balance: 0
};

const OTHER_TYPE = 'other';

const { TYPE_ADD_PROPERTIES, TYPE_ADD_PROPERTIES_SPOUSE } = contextActionTypes;

const notOwnedFormData = {
    verified: false,
    address: null,
    primary_residence: false,
};

function Property({ spouse }) {
    const formRef = useRef();
    const { property_id } = useParams();
    const [usedSecondMortgage, updateUsedSecondMortgage] = useLocalStorage('used_second_mortgage', []);
    const [initSecondMortgage, setInitSecondMortgage] = useState([]);
    const [isSecondMortgage, setIsSecondMortgage] = useState(false);
    const { personalUid } = useContext(PersonalInfoContext);
    const [initialValues, updateInitialValues] = useState(
        Object.assign({}, initData)
    );
    const [propertyData, setPropertyData] = useState({});

    const { previousStep, nextStep } = useStepController();
    const { investments, dispatchInvestments } = useContext(InvestmentContext);

    const [secondMortgageData, setSecondMortgageData] = useState([]);
    const [osBalanceSum, setOSBalanceSum] = useState(0);

    const { source_details, outstanding_balance } = propertyData;
    const { properties_financed, properties_financed_spouse } = investments;

    // Mortgage Properties filtered by spouse
    const mortgageProperties = useMemo(() => {
        return spouse ? properties_financed_spouse : properties_financed;
    }, [spouse, properties_financed, properties_financed_spouse]);

    useEffect(() => {
        const propertyData = mortgageProperties.find(
            (property) => property.uid === property_id
        );
        if (propertyData) {

            getCurrentProperty(personalUid).then((propertiesData) => {

                let currentProperty;
                const propertyIndex = propertiesData.findIndex((p) => p.uid === property_id);
                if (propertyIndex > -1) {
                    currentProperty = propertiesData[propertyIndex];
                    setOSBalanceSum(currentProperty.refinance_loan_balance);
                }
                const {
                    has_refinance_mortgage,
                    second_mortgages,
                    refinance_loan_balance
                } = currentProperty;

                const existingMortgage = [];
                propertiesData.forEach((property) => {
                    property.second_mortgages.forEach((mortgage) => {
                        if (property.uid != property_id) {
                            existingMortgage.push(mortgage.id);
                        }
                    });
                });
                fetchSecondMortgage(personalUid, existingMortgage);

                setPropertyData(propertyData);

                const {
                    verified,
                    market_value,
                    outstanding_balance,
                    primary_residence,
                    address,
                    other_address,
                } = propertyData;

                const mortgageData = second_mortgages;
                const mortgageArray = [];
                const temp_second_mortgages = [];
                mortgageData.forEach((item) => {
                    const mortgage = {
                        value: item.id,
                        label: item.source_details,
                        balance: item.outstanding_balance,
                    };
                    mortgageArray.push(mortgage);

                    let temp_second_mortgage = {};

                    if (item.intermediate_id) {
                        temp_second_mortgage = {
                            id: item.intermediate_id,
                            second_mortgage: item.id,
                            primary_mortgage: propertyData.id,
                            value: item.value,
                            label: item.label,
                            balance: item.balance,
                        };

                    }
                    else {
                        temp_second_mortgage = {
                            second_mortgage: item.value,
                            primary_mortgage: propertyData.id,
                            value: item.value,
                            label: item.label,
                            balance: item.balance,
                        };
                    }
                    temp_second_mortgages.push(temp_second_mortgage);
                });
                setInitSecondMortgage(mortgageArray);

                updateInitialValues({
                    ...initData,
                    verified,
                    market_value: market_value || '',
                    outstanding_balance: outstanding_balance || '',
                    primary_residence,
                    // Checking for keep manual Other option selected
                    address:
                        address === null
                            ? verified
                                ? OTHER_TYPE
                                : ''
                            : address.uid,
                    other_address: other_address ? other_address : {},
                    refinance_loan_balance: refinance_loan_balance,
                    has_refinance_mortgage: has_refinance_mortgage,
                    second_mortgages: temp_second_mortgages,
                });
            });
        }
    }, [property_id, mortgageProperties, personalUid, fetchSecondMortgage, isSecondMortgage, getCurrentProperty]);

    // Check client has selected a property as primary residence.
    const hasPrimaryResidence = useMemo(() => {
        if (
            'primary_residence' in propertyData &&
            !propertyData.primary_residence
        ) {
            const filteredProperties = mortgageProperties.filter(
                (p) => p.uid !== propertyData.uid
            );
            const propThatPrimary = filteredProperties.find(
                (p) => p.primary_residence === true
            );

            return !!propThatPrimary;
        } else {
            return false;
        }
    }, [propertyData, mortgageProperties]);

    const getCurrentProperty = useCallback(async (profile) => {
        try {
            const req = await api({
                url: endpoints.SECOND_MORTGAGE,
                params: {
                    profile,
                    is_second_mortgage: false,
                },
            });
            const propertiesData = await req.data;
            return propertiesData;
        } catch (error) {
            console.log({ error });
        }
    }, []);

    const fetchSecondMortgage = useCallback(async (profile, existingMortgage) => {
        try {
            const req = await api({
                url: endpoints.SECOND_MORTGAGE,
                params: {
                    profile,
                    is_second_mortgage: true,
                },
            });
            const mortgageData = await req.data;
            const mortgageArray = [];

            mortgageData.forEach((item) => {
                const mortgage = {
                    value: item.id,
                    label: item.source_details,
                    balance: item.outstanding_balance,

                };
                if (!existingMortgage.some((mortgage) => mortgage === item.id)) {
                    mortgageArray.push(mortgage);
                }
            });
            setSecondMortgageData(mortgageArray);
        } catch (error) {
            console.log({ error });
        }
    }, []);


    const saveProperty = async (values) => {
        values['market_value'] = parseAmount(values.market_value);
        values['outstanding_balance'] = values.outstanding_balance
            ? parseAmount(values.outstanding_balance)
            : 0;
        const properties = [...mortgageProperties];
        const formData = Object.assign({}, values);
        const { verified, address, primary_residence } = formData;
        const { uid } = propertyData;
        const thisPropertyIndex = properties.findIndex((p) => p.uid === uid);

        if (verified) {
            // Clear Other address entry if address is any mortgage address
            if (address === OTHER_TYPE) {
                formData.address = null;
            } else {
                formData.other_address = null;
            }

            // Other address is also null if it's a primary residence
            if (primary_residence) {
                formData.other_address = null;
            }

            // Address can't be empty string!
            // Set address = "" on form update section at above due to make mortgage unselected.
            if (address === '') {
                formData.address = null;
            }
        }

        try {
            const req = await api({
                method: 'patch',
                url: `${endpoints.DYNAMIC_PROPERTY}${uid}/`,
                data: verified ? formData : notOwnedFormData,
            });

            const res = await req.data;

            properties.splice(thisPropertyIndex, 1, res);

            // Update context with changed property
            if (spouse) {
                dispatchInvestments({
                    type: TYPE_ADD_PROPERTIES_SPOUSE,
                    properties_financed_spouse: properties,
                });
            } else {
                dispatchInvestments({
                    type: TYPE_ADD_PROPERTIES,
                    properties_financed: properties,
                });
            }

            history.push(nextStep.path);
        } catch (e) {
            console.log(e.response);
        }
    };

    const mSelectOnChange = (valueArray, option) => {
        let osb = 0;
        const second_mortgages = [];
        valueArray.forEach((item) => {
            osb = parseAmount(osb) + parseAmount(item.balance);
            second_mortgages.push({
                second_mortgage: item.value,
                primary_mortgage: propertyData.id,
                value: item.value,
                label: item.label,
                balance: item.balance,
            });
        });

        formRef.current.setFieldValue('second_mortgages', second_mortgages);
        formRef.current.setFieldValue('refinance_loan_balance', osb);
        setOSBalanceSum(osb);

        const tempUsedSecondMortgage = usedSecondMortgage;
        if (option.action == 'remove-value') {
            if (tempUsedSecondMortgage.includes(option.removedValue.value)) {
                tempUsedSecondMortgage.splice(tempUsedSecondMortgage.indexOf(option.removedValue.value), 1);
            }
        }
        else if (option.action == 'select-option') {
            if (!tempUsedSecondMortgage.includes(option.option.value)) {
                tempUsedSecondMortgage.push(option.option);
            }
        }
        else if (option.action == 'clear') {
            setInitSecondMortgage([]);
            setOSBalanceSum(0);
        }

        updateUsedSecondMortgage(tempUsedSecondMortgage);
    }

    const handleChange = (e) => {
        formRef.current.setFieldValue('has_refinance_mortgage', e);
        setIsSecondMortgage(e);
    }

    return (
        <LoadGoogleMapScript>
            <Formik
                initialValues={initialValues}
                onSubmit={saveProperty}
                validationSchema={validation}
                enableReinitialize
                validateOnMount
                innerRef={formRef}

            >
                {({ values, handleSubmit, isValid }) => {
                    const { verified, primary_residence } = values;

                    const isVerified =
                        typeof verified === 'boolean' && verified;

                    return (
                        <form>
                            {spouse ? (
                                <h1>
                                    Does your spouse still own the property
                                    financed with{' '}
                                    <span className="color-blue">
                                        {source_details}
                                    </span>{' '}
                                    {outstanding_balance <= 0
                                        ? 'which is closed'
                                        : ''}
                                    {outstanding_balance > 0
                                        ? 'with an outstanding balance of'
                                        : ''}{' '}
                                    {outstanding_balance > 0 ? (
                                        <span className="color-blue">
                                            ${outstanding_balance}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </h1>
                            ) : (
                                <h1>
                                    Do you still own the property you financed
                                    with{' '}
                                    <span className="color-blue">
                                        {source_details}
                                    </span>{' '}
                                    {outstanding_balance <= 0
                                        ? 'which is closed'
                                        : ''}
                                    {outstanding_balance > 0
                                        ? 'with an outstanding balance of'
                                        : ''}{' '}
                                    {outstanding_balance > 0 ? (
                                        <span className="color-blue">
                                            ${outstanding_balance}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                    ?
                                </h1>
                            )}

                            <SwitchInput name="verified" className="my-32" />

                            {isVerified && <EstimatedMarketValue />}
                            {isVerified && <EstimatedLoanBalance />}

                            {isVerified && !hasPrimaryResidence && (
                                <PrimaryResidence spouse={spouse} />
                            )}

                            {isVerified ? (
                                hasPrimaryResidence ? (
                                    <PropertyAddress spouse={spouse} />
                                ) : typeof primary_residence === 'boolean' ? (
                                    primary_residence ? null : (
                                        <PropertyAddress spouse={spouse} />
                                    )
                                ) : null
                            ) : null}

                            {isVerified && secondMortgageData.length > 0 && <>
                                <Fragment>
                                    <h2 className="mt-32">
                                        Does this mortgage have 2nd mortgage?
                                    </h2>
                                    <SwitchInput onChange={handleChange} className="my-32" name="has_refinance_mortgage" />
                                </Fragment>
                                {
                                    values.has_refinance_mortgage && <Fragment>
                                        <label className="bx--label" >Second Mortgage</label>
                                        <MultiSelect placeholder='Select Second Mortgage'
                                            options={secondMortgageData}
                                            defaultValue={initSecondMortgage}
                                            onChange={mSelectOnChange}

                                        />
                                        <br />
                                        <FormGroup legendText={''}>
                                            <AmountInput
                                                placeholder="$"
                                                name="refinance_loan_balance"
                                                labelText="Outstanding Balance"
                                                value={osBalanceSum}
                                            />
                                        </FormGroup>
                                    </Fragment>
                                }

                            </>}


                            <Navigation
                                disableNext={!isValid}
                                onNext={handleSubmit}
                                onPrevious={() =>
                                    history.push(previousStep.path)
                                }
                            />
                        </form>
                    );
                }}
            </Formik>
        </LoadGoogleMapScript>
    );
}

const PrimaryResidence = ({ spouse }) => (
    <Fragment>
        <h2 className="mt-32">
            Is this your {spouse && "spouse's "} primary residence?
        </h2>

        <SwitchInput className="my-32" name="primary_residence" />
    </Fragment>
);

const EstimatedMarketValue = () => (
    <Fragment>
        <h2>What is the estimated market value?</h2>
        <FormGroup legendText={''}>
            <AmountInput
                placeholder="$"
                name="market_value"
                labelText="Estimated Market Value"
            />
        </FormGroup>
    </Fragment>
);


const EstimatedLoanBalance = () => (
    <Fragment>
        <h2>What is the Estimated loan balance?</h2>
        <FormGroup legendText={''}>
            <AmountInput
                placeholder="$"
                name="outstanding_balance"
                labelText="Estimated Loan Balance"
            />
        </FormGroup>
    </Fragment>
);

enableDotAmountCheck();
enableMinAmountCheck();
enableMaxAmountCheck();

const validation = Yup.object().shape({
    verified: Yup.boolean().required('Please select an option'),
    primary_residence: Yup.boolean().nullable(),
    has_refinance_mortgage: Yup.boolean().nullable(),
    refinance_loan_balance: Yup.string().nullable()
        .when('has_refinance_mortgage', {
            is: true,
            then: Yup.string()
                .required('Required field')
                .onlyDot('Your market value is not valid')
                .minAmount(1, 'Minimum amount is 1'),
        }),
    market_value: Yup.string()
        .nullable()
        .when('verified', {
            is: true,
            then: Yup.string()
                .required('Required field')
                .onlyDot('Your market value is not valid')
                .minAmount(10000, 'Minimum amount is 10,000')
                .maxAmount(
                    MAX_AMOUNT_PROPERTIES,
                    `Maximum amount is ${toUsCurrency(MAX_AMOUNT_PROPERTIES)}`
                ),
        }),
    address: Yup.string()
        .nullable()
        .when(['verified', 'primary_residence'], {
            is: (verified, primary_residence) => verified && !primary_residence,
            then: Yup.string().required('Please select an address'),
        }),
    other_address: Yup.object().when(['address', 'primary_residence'], {
        is: (address, primary_residence) =>
            address === OTHER_TYPE && !primary_residence,
        then: Yup.object().shape({
            line_1: Yup.string()
                .required('Required field')
                .min(MIN_ADDRESS_CHARS, 'Minimum 2 characters')
                .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
            line_2: Yup.string()
                .nullable()
                .min(MIN_ADDRESS_CHARS, 'Minimum 2 characters')
                .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
            city: Yup.string().required('Required field'),
            zip_code: Yup.string().required('Required field').zipCodeValidate(),
            state: Yup.string().required('Required field'),
        }),
        otherwise: Yup.object().nullable(),
    }),
});

export default Property;