const api_ver = 'api/v1';
const back_check = `back-check/${api_ver}`;
const transaction = `transaction/${api_ver}`;
const plaid = `plaid/${api_ver}`;
const vehicle_info_update = 'assets/api/v1/dynamic/vehicle/';
const CLIENT_CHECK = `/otp/${api_ver}/client/check/`;
const OTP_INIT = `/otp/${api_ver}/auth/init/`;
const OTP_LOGIN = `/user/${api_ver}/token/`;
const TOKEN = '/m/token/';
const PERSONAL_INFO = `/personal-info/${api_ver}`;
const PERSONAL_PROFILE = `${PERSONAL_INFO}/profile/`;
const FILING_OPTIONS = `${PERSONAL_INFO}/get-filing-status-options/`;
const FILING_STATUS = `${PERSONAL_INFO}/filing-status/`;
const VERIFY_CONTACT = `${PERSONAL_INFO}/profile-contact/`;
const DEPENDENTS_NUMBER = `${PERSONAL_INFO}/get-dependent-no-options/`;
const DEPENDENT = `${PERSONAL_INFO}/dependent/`;
const RELATIONSHIP_LIST = `${PERSONAL_INFO}/get-relationship-list/`;
const NAME_LIST = `/${back_check}/client-names/`;
const ADDRESS_LIST = `/${back_check}/client-addresses/`;
const EXPERIAN_CREDIT_DATA = 'credit-report/api/v1/process/';
const CONNECTED_BANK_INFO = `/${plaid}/bank-information/`;
const ADD_BANK_TOKEN = `/${plaid}/pre-process/`;
const BANK_ACCOUNT_SOURCES = `/${back_check}/bank-account-sources/`;
const BANK_ACCOUNT_SOURCES_UPDATE = `/${back_check}/bank-account-sources/bulk_update/`;
const POST_PROCESS_BANK = `/${back_check}/post-process-bank/`;
const PLAID_TRANSACTION_PULL_STATUS = `/${plaid}/transaction-pull-status/`;
const PROPERTIES_FINANCED = `/assets/${api_ver}/dynamic/property/`;
const VEHICLES_FINANCED = `/assets/${api_ver}/dynamic/vehicle/`;
const CLIENT_ADDRESSES = `${back_check}/client-addresses/`;
const ADDITIONAL_PROPERTY = `/assets/${api_ver}/property/`;
const ADDITIONAL_VEHICLE = `/assets/${api_ver}/vehicle/`;
const DYNAMIC_PROPERTY = `/assets/${api_ver}/dynamic/property/`;
const EMPLOYER_LIST = `${back_check}/client-employer/`;
const ESTIMATE_INCOME = `${back_check}/estimate-emp-income/`;
const ESTIMATE_ADDITIONAL_INCOME = 'additional-income/api/v1/estimation/';
const CLIENT_INCOME = `${back_check}/client-income/`;
const AVAILABLE_DEPOSIT = `${transaction}/available-deposit/`;
const BUSINESS_AVAILABLE_DEPOSIT = `${transaction}/available-deposit/`;
const DYNAMIC_VEHICLE_UPDATE = `${vehicle_info_update}`;
const VEHICLE_UPDATE = 'assets/api/v1/vehicle/';
const FINAL_REVIEW = `${back_check}/final-review/`;
const FINAL_STATUS = 'final-output-process/api/v1/pdf/';
const CREDIT_REPORT_PROCESS = 'credit-report/api/v1/post-process/';
const DEPOSIT_REASON = 'additional-income/api/v1/deposit-reason/';
const EMPLOYER_INFO = `back-check/${api_ver}/client-employer/`;
const ADDITIONAL_INCOME_INSERT = 'additional-income/api/v1/additional-income/';
const SELF_EMPLOYED = `/self-employed/${api_ver}`;
const SELF_EMPLOYED_BUSINESS_INFO = `${SELF_EMPLOYED}/business-info/`;
const SELF_EMPLOYED_FINANCIAL_INFO = `${SELF_EMPLOYED}/business-financial-info/`;
const SELF_EMPLOYED_BUSINESS_ASSET_INFO = `${SELF_EMPLOYED}/business-asset-info/`;
const SELF_EMPLOYED_BUSINESS_RECEIVABLE_INFO = `${SELF_EMPLOYED}/business-receivable-info/`;
const SELF_EMPLOYED_AVERAGE_INCOME_ESTIMATION = `${SELF_EMPLOYED}/income-info/`;
const SELF_EMPLOYED_MANUAL_INCOME = `${SELF_EMPLOYED}/business-income-expense/`;
const CLIENT_ADDITIONAL_INFO = `${back_check}/client-additional-info/`;
const CLIENT_EMPLOYMENT_EXPENSES =
    'expense-info/api/v1/client-employment-expense/';
const EMPLOYMENT_INCOME = `/employment-income/api/v1`;
const EMPLOYMENT_INCOME_INFO = `${EMPLOYMENT_INCOME}/income-info/`;
const GENERATE_EMPLOYER_NAME = 'employer-info/api/v1/generate-employer-name/';
const EXTERNAL_CONNECT = '/user/api/v1/cp-connect/';
const INVESTMENT_ACCOUNTS = '/other-info/api/v1/account-information/';
const SECOND_MORTGAGE = 'assets/api/v1/dynamic/property/';

export default {
    CLIENT_CHECK,
    OTP_INIT,
    OTP_LOGIN,
    TOKEN,
    PERSONAL_INFO,
    PERSONAL_PROFILE,
    FILING_OPTIONS,
    FILING_STATUS,
    VERIFY_CONTACT,
    DEPENDENTS_NUMBER,
    DEPENDENT,
    RELATIONSHIP_LIST,
    NAME_LIST,
    ADDRESS_LIST,
    EXPERIAN_CREDIT_DATA,
    CONNECTED_BANK_INFO,
    ADD_BANK_TOKEN,
    BANK_ACCOUNT_SOURCES,
    BANK_ACCOUNT_SOURCES_UPDATE,
    POST_PROCESS_BANK,
    PROPERTIES_FINANCED,
    VEHICLES_FINANCED,
    CLIENT_ADDRESSES,
    ADDITIONAL_PROPERTY,
    ADDITIONAL_VEHICLE,
    DYNAMIC_PROPERTY,
    EMPLOYER_LIST,
    ESTIMATE_INCOME,
    CLIENT_INCOME,
    AVAILABLE_DEPOSIT,
    DYNAMIC_VEHICLE_UPDATE,
    VEHICLE_UPDATE,
    CREDIT_REPORT_PROCESS,
    DEPOSIT_REASON,
    FINAL_STATUS,
    ADDITIONAL_INCOME_INSERT,
    EMPLOYER_INFO,
    ESTIMATE_ADDITIONAL_INCOME,
    FINAL_REVIEW,
    SELF_EMPLOYED_BUSINESS_INFO,
    SELF_EMPLOYED_FINANCIAL_INFO,
    SELF_EMPLOYED_BUSINESS_ASSET_INFO,
    SELF_EMPLOYED_BUSINESS_RECEIVABLE_INFO,
    SELF_EMPLOYED_AVERAGE_INCOME_ESTIMATION,
    SELF_EMPLOYED_MANUAL_INCOME,
    BUSINESS_AVAILABLE_DEPOSIT,
    CLIENT_ADDITIONAL_INFO,
    CLIENT_EMPLOYMENT_EXPENSES,
    EMPLOYMENT_INCOME_INFO,
    GENERATE_EMPLOYER_NAME,
    PLAID_TRANSACTION_PULL_STATUS,
    EXTERNAL_CONNECT,
    INVESTMENT_ACCOUNTS,
    SECOND_MORTGAGE
};
